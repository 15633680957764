import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIUsers {

  getUrlDomainApi(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "api/" + nome_metodo;

  }


  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async getAziende(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("getAziende"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getAziendeNew(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("getAziendeNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getInfoTsEbadge(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getInfoUserTsEbadge"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getFilterAziende(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("getFilterAziende"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getFilterAziendeTSForn(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("getFilterAziendeTSForn"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async getFilterAziendeTSCliente(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getFilterAziendeTSCliente"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getPrefix() {

    return axios.get(this.getUrlDomain("getPrefix"), { 'Content-Type': 'multipart/form-data' });

  }

  async getUtenti(user, company) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("company", company);

    return axios.post(this.getUrlDomain("getUtenti"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async createUtenteApi(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("createUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async createUtenteNewApi(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("initInsertUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async goCreateUtenteNewApi(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("goInitInsertUser"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getUtente(id, user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("EmtGetUserForEdit"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getUtenteNew(id, user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("EmtGetUserNewForEdit"), formData, { 'Content-Type': 'multipart/form-data' });

  }




  async goGetUtenteNew(id, user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("val_id", id);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("goInitEditUser"), formData, { 'Content-Type': 'multipart/form-data' });

  }






  async deleteUtente(id, user) {


    return axios.get(this.getUrlDomain("delUserApi") + "?id=" + id + "&username=" + user, { 'Content-Type': 'multipart/form-data' });

  }



  async insertUtente(utente, gruppi, user) {

    let formData = new FormData();

    console.log("UTENTE 4", utente);

    console.log(user);


    var selected_model = "";

    for (var i = 0; i < gruppi.length; i++) {

      if ((gruppi[i] !== undefined) && (gruppi[i].checked !== undefined)) {

        if (gruppi[i].checked != null) {

          if (selected_model != "") {
            selected_model = selected_model + ",";
          }
          selected_model = selected_model + gruppi[i].checked;

        }

      }

    }

    formData.append("username", user);
    formData.append("selected_model", selected_model);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }


    return axios.post(this.getUrlDomain("insUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async insertUtenteNew(utente, gruppi, user) {

    let formData = new FormData();

    console.log("UTENTE 4", utente);

    console.log(user);


    var selected_model = "";

    for (var i = 0; i < gruppi.length; i++) {

      if ((gruppi[i] !== undefined) && (gruppi[i].checked !== undefined)) {

        if (gruppi[i].checked != null) {

          if (selected_model != "") {
            selected_model = selected_model + ",";
          }
          selected_model = selected_model + gruppi[i].checked;

        }

      }

    }

    formData.append("username", user);
    formData.append("selected_model", selected_model);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }


    return axios.post(this.getUrlDomain("insUserNewApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async insertUtenteNew2(utente, selected_model, user) {

    let formData = new FormData();

    console.log("UTENTE 4", utente);

    console.log(user);

    formData.append("username", user);
    formData.append("selected_model", selected_model);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }


    return axios.post(this.getUrlDomain("insUserNewApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  async insertUtenteNew3(utente, selected_model, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("selected_model", selected_model);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }

    return axios.post(this.getUrlDomain("insertUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }







  async updateUtente(utente, gruppi, user, id) {

    let formData = new FormData();

    var selected_model = "";

    for (var i = 0; i < gruppi.length; i++) {

      if ((gruppi[i] !== undefined) && (gruppi[i].checked !== undefined)) {

        if (gruppi[i].checked != null) {

          if (selected_model != "") {
            selected_model = selected_model + ",";
          }
          selected_model = selected_model + gruppi[i].checked;

        }

      }

    }

    formData.append("username", user);
    formData.append("selected_model", selected_model);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }

    return axios.post(this.getUrlDomain("updUserApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }



  async updateUtenteNew(utente, gruppi, user, id) {

    let formData = new FormData();

    var selected_model = "";

    for (var i = 0; i < gruppi.length; i++) {

      if ((gruppi[i] !== undefined) && (gruppi[i].checked !== undefined)) {

        if (gruppi[i].checked != null) {

          if (selected_model != "") {
            selected_model = selected_model + ",";
          }
          selected_model = selected_model + gruppi[i].checked;

        }

      }

    }

    formData.append("username", user);
    formData.append("selected_model", selected_model);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }

    return axios.post(this.getUrlDomain("updUserNewApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateUtenteNew2(utente, selected_model, user, id) {

    let formData = new FormData();


    formData.append("username", user);
    formData.append("selected_model", selected_model);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }

    return axios.post(this.getUrlDomain("updUserNewApi") + "/" + id, formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateUtenteNew3(utente, selected_model, user, v_id) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("selected_model", selected_model);
    formData.append("v_id", v_id);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }

    return axios.post(this.getUrlDomain("updateUserApi"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  async createResources(user, id_user_sel, risorsa) {

    let formData = new FormData();

    formData.append("username", user);

    formData.append("id_user_sel", id_user_sel);

    for (var key in risorsa) {
      formData.append(key, risorsa[key]);
    }

    return axios.post(this.getUrlDomain("EmtCreateResFromUsers"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async validateCreateResources(id_user_sel, user, companyid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id_user_sel", id_user_sel);
    formData.append("companyid", companyid);


    return axios.post(this.getUrlDomain("EmtCheckCreateResFromUsers"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async validateCreateQRCode(id_user_sel, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_user_sel);


    return axios.post(this.getUrlDomain("EmtUserCreateQRCodeValidate"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async createQRCode(id_user_sel, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_user_sel);


    return axios.post(this.getUrlDomain("EmtUserCreateQRCode"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async createQRCodeSingle(id_user_sel, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id_user_sel);


    return axios.post(this.getUrlDomain("EmtUserCreateQRCodeSingle"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async sendPswToSU(id, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("id", id);

    return axios.post(this.getUrlDomain("EmtUserSendPswToSU"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async checkConfTsDip(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("EmtCheckConfTsDip"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async checkChangePwd(user, spec_psw) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("spec_psw", spec_psw);

    return axios.post(this.getUrlDomainApi("pswUserIsChanged"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async validateDeleteUser(ids, user) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("ids", ids);

    return axios.post(this.getUrlDomain("validateDeleteUser"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async checkLogout(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("checkLogout"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async registerLogin(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("registerLogin"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async registerLogout(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("registerLogout"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async prevMailDataUser(v_id, user, v_company) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("username", user);
    formData.append("v_company", v_company);

    return axios.post(this.getUrlDomain("prevMailDataUser"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sendMailDataUser(v_id, mail_body) {

    let formData = new FormData();

    formData.append("v_id", v_id);
    formData.append("mail_body", mail_body);

    return axios.post(this.getUrlDomain("sendMailDataUserNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getLastLogin(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getLastLogin"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getEmtProducts(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getEmtProducts"), formData, { 'Content-Type': 'multipart/form-data' });

  }





  async registerLoginNew(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("registerLogin"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  // async registerLoginNew(user, productid) {

  //   let formData = new FormData();

  //   formData.append("username", user);
  //   formData.append("productid", productid);

  //   return axios.post(this.getUrlDomain("goRegisterLogin"), formData, { 'Content-Type': 'multipart/form-data' });

  // }



  async checkLogoutNew(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("checkLogoutNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async registerLogoutNew(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("registerLogout"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  // async registerLogoutNew(user, productid) {

  //   let formData = new FormData();

  //   formData.append("username", user);
  //   formData.append("productid", productid);

  //   return axios.post(this.getUrlDomain("goRegisterLogout"), formData, { 'Content-Type': 'multipart/form-data' });

  // }

  async getLastLoginNew(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("getLastLogin"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getClienti(user, productid) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("productid", productid);

    return axios.post(this.getUrlDomain("getClientiEmt"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  
  async getImageBackGroundCompany(companyid) {

    let formData = new FormData();

    formData.append("companyid", companyid);

    return axios.post(this.getUrlDomain("getImageBackGroundCompany"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  
  
  async getCompany(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("getCompany"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getCompanyNew(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("getCompanyNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getFilterAziendeNew(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("getFilterAziendeNew"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getTipoGruppo(user) {

    let formData = new FormData();

    formData.append("username", user);

    return axios.post(this.getUrlDomain("getTipoGruppo"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async insertUtenteSU(utente) {

    let formData = new FormData();

    for (var key in utente) {
      formData.append(key, utente[key]);
    }


    return axios.post(this.getUrlDomain("insUserSU"), formData, { 'Content-Type': 'multipart/form-data' });

  }



  async getUtenteSU(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);
 
    return axios.post(this.getUrlDomain("getUtenteSU"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async updateUtenteSU(utente, v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    for (var key in utente) {
      formData.append(key, utente[key]);
    }


    return axios.post(this.getUrlDomain("updateUtenteSU"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async deleteUtenteSU(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("deleteUtenteSU"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async sbloccaUtenti(v_id) {

    let formData = new FormData();

    formData.append("v_id", v_id);

    return axios.post(this.getUrlDomain("sbloccaUtenti"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getFilterUtenti(v_token) {

    let formData = new FormData();

    formData.append("v_token", v_token);

    return axios.post(this.getUrlDomain("getFilterUtenti"), formData, { 'Content-Type': 'multipart/form-data' });

  }


  async getFilterAziendeLog(user, prodotto) {

    let formData = new FormData();

    formData.append("username", user);
    formData.append("prodotto", prodotto);

    return axios.post(this.getUrlDomain("getFilterAziendeLog"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async associaRisorsa(id_risorsa, id_user) {

    let formData = new FormData();

    formData.append("id_risorsa", id_risorsa);
    formData.append("id_user", id_user);

    return axios.post(this.getUrlDomain("associaRisorsaUtente"), formData, { 'Content-Type': 'multipart/form-data' });

  }

  async getCntPrjRis(id_user) {

    let formData = new FormData();

    formData.append("id_user", id_user);

    return axios.post(this.getUrlDomain("cntProjAssocRis"), formData, { 'Content-Type': 'multipart/form-data' });

  }



}

export default new APIUsers({
  url: "http://emtool.local/public/api/smartbook/"
})
